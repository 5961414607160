function select (selector) {
  return Array.prototype.slice.call(document.querySelectorAll(selector))
}

function initLightBox () {
  const isImageUrl = (url) => {
    return !!url.match(/^.+\.jpg$/)
  }

  const isLightBox = link => {
    return link.classList.contains('lightbox')
  }

  const setLightBox = link => {
    const prev = document.querySelector('.lightbox')
    if (prev) prev.classList.remove('lightbox')
    if (link) link.classList.add('lightbox')
  }

  const close = () => {
    setLightBox(null)
  }

  // convert full URL to hash
  const links = Array.from(document.querySelectorAll('a'))
    .filter(link => isImageUrl(link.href))

  links.forEach(link => {
    link.addEventListener('click', e => {
      e.preventDefault()
      if (isLightBox(link)) {
        close()
      } else {
        setLightBox(link)

        // close by moving focus
        link.onblur = () => close()

        // close via Escape
        window.onkeydown = e => {
          if (e.key === 'Escape') close()
        }
      }
    })
  })
}

function initGalleries () {
  select('.gallery').forEach(function (gallery) {
    const count = gallery.dataset.count
    const path = gallery.dataset.path
    for (let i = 0; i < count; i++) {
      const url = path + (i + 1) + '.jpg'
      const img = document.createElement('img')
      const a = document.createElement('a')
      a.appendChild(img)
      gallery.appendChild(a)
      img.src = url
      img.alt = 'Originalgröße'
      a.href = url
    }
  })
}

function initJury () {
  const show = function (node) {
    node.removeAttribute('hidden')
  }
  const hide = function (node) {
    node.setAttribute('hidden', '')
  }

  select('.jury-member').forEach(function (member) {
    const toggle = member.querySelector('.jury-member__toggle')
    const bio = member.querySelector('.jury-member__bio')

    hide(bio)
    show(toggle)

    toggle.addEventListener('click', function () {
      hide(toggle)
      show(bio)
    })
  })
}

function initSlider () {
  function nextSlide () {
    const lastChild = function (element) {
      return element.children[element.children.length - 1]
    }
    const slider = document.getElementById('slider')
    const oldSlide = lastChild(slider)
    oldSlide.hidden = false
    slider.insertBefore(oldSlide, slider.children[0])
    lastChild(slider).hidden = true
  }

  const automatic = setInterval(nextSlide, 5000)
  const nextButton = document.getElementById('slider-next')
  nextButton.addEventListener('click', function () {
    clearInterval(automatic)
    nextSlide()
  })
}

function initNav () {
  const active = document.documentElement.dataset.active
  const item = document.querySelector(`[data-page="${active}"]`)
  if (item) item.classList.add('active')
}

initNav()
initGalleries()
initJury()
initSlider()
initLightBox()
